import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Warning`}</h2>
    <p><strong parentName="p">{`New Windows Phone 8 icons are now available `}<a parentName="strong" {...{
          "href": "windows-phone/windows-phone-8-application-bar-icons.md"
        }}>{`here`}</a>{`!`}</strong></p>
    <h2>{`Download`}</h2>
    <p><a parentName="p" {...{
        "href": "/2610b26dbd4cebf77f3c8102196b3f00/WP71AppbarIcons.zip"
      }}>{`Download here!`}</a></p>
    <h2>{`Last Update`}</h2>
    <p>{`12-09-2011`}</p>
    <h2>{`About`}</h2>
    <p>{`The Windows Phone 7 development team added a set of 32 icons to the `}<abbr parentName="p" {...{
        "title": "Software Development Kit"
      }}>{`SDK`}</abbr>{` to be used on the Application Bar by developers of external applications.`}</p>
    <p>{`This number seemed quite low to get things done, even more after seeing the other icons spread across the native Windows Phone 7 applications (like the “send” icon on the messages, the “attach” icon on the mail app, etc.)`}</p>
    <p>{`Thus, I now present you a collection of 132 extra icons in comparison to the 32 that comes on the `}<abbr parentName="p" {...{
        "title": "Software Development Kit"
      }}>{`SDK`}</abbr>{` (please click on the image below to get a full size visualization of the collection)`}</p>
    <p><a parentName="p" {...{
        "href": "/63f5e1e542340ca35f1b535b0f970de1/Windows-Phone-7-Application-Bar-Icons.png"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "480px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABoklEQVQoz02S27ajIAxAfWlFCYIQAjncpLb+/yeeVZmZNfuBByAXNpne7/fPzw8iKqW2bWutnefJzCGEsS+lNMZorY0x27YhYkppHE1KKeccIq7rum2bc46IYoznedZaiWieZwBYlsVa23tPKZ3nSURKqW8w3AghQgha623bvPfpptzsN0QUQkgptdZGsYmIpJQAYIxRSiGic05KKYQAAEQspQDAvu/GmGVZhBDjFVLK6fV69d6JSAjx+XxKKcYYKaX3XmutlLLWIuKozMxSyufzqZQKIUzDzTje952ZiWhZFgDQWnvvQwgAYK0lIq01AAy1X2HM7JwzxiAiALibeZ6ttSPyOA7nnPfeGLOuq7V2mPfeT+vNkImIz5vH4+G9H33mnK/rYmattbX2PM/hsrU21VpLKbVWZk4pOedCCL33dsPMxhhmHteYeawxxuu6ptZazrmUknN+vV4jUUqp934cx7jaex/ZW2v15k/lf/lqrTnn7/Sk5O85yTnXWhEx5xxjDCHEGO1fvsJGP8dxENG+7wAgb9Z1DTdD7/iO79/+xy++HDouxLULNAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Windows Phone 7 Application Bar Icons",
            "title": "Windows Phone 7 Application Bar Icons",
            "src": "/static/db2aec641cff33083ebe5c9309b9cf64/e85cb/Windows-Phone-7-Application-Bar-Icons-thumb.png",
            "srcSet": ["/static/db2aec641cff33083ebe5c9309b9cf64/e9ff0/Windows-Phone-7-Application-Bar-Icons-thumb.png 180w", "/static/db2aec641cff33083ebe5c9309b9cf64/f21e7/Windows-Phone-7-Application-Bar-Icons-thumb.png 360w", "/static/db2aec641cff33083ebe5c9309b9cf64/e85cb/Windows-Phone-7-Application-Bar-Icons-thumb.png 480w"],
            "sizes": "(max-width: 480px) 100vw, 480px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`A large part of these icons are really the original ones that show up in the Windows Phone 7 apps (extracted from the application resources), others where simply modified to get the correct measures (changing the “canvas” to 48x48, the right size to this type of icons).`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` On the previous image, the icons with a white rectangle indicate that they where extracted from the Windows Phone 7.1 files!`}</p>
    <p>{`The icons are here free of charge, all I ask is that you please link to this page and not to the file per say! ;)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      